<template>
  <div>
		<div class="title" >设置默认征订书内容</div>
		<div class="type" >
			<el-radio-group v-model="noticeType" @change="getDefaultRemind">
				<el-radio label="student">学生征订</el-radio>
				<el-radio label="teacher">教师征订</el-radio>
  		</el-radio-group>
		</div>
    <div class="content" id="remindDiv" ></div>
    <div class="save" >
    	<el-button type="primary" v-debounce="setDefaultRemind" >保存</el-button>
    </div>
  </div>
        
</template>

<script>
import { setDefaultRemind, getDefaultRemind } from '@/api/index.js'
import E from 'wangeditor'
import { baseURL } from '@/api/http';
var editor = null;
export default {
  name: 'remind',
  components: {  },
  data() {
    return {
      remind:'',
			noticeType:'student'
    }     
    
  },
  created() {
   this.getDefaultRemind();
  },
  mounted() {
    
  },
	beforeDestroy(){
		editor.destroy()
    editor = null
	},
  methods: {
    async getDefaultRemind(){
      let param = {
				type:this.noticeType
			};      
      const res = await getDefaultRemind(param)
      if (res.code != 200) return 
			this.initEditor(res.data);
    },
		initEditor(html){
			if(editor){
				editor.txt.html(html)
			}else{
					this.$nextTick(() => {
						editor = new E('#remindDiv');
						editor.config.height = 450;
						editor.config.uploadImgServer = baseURL + '/uploadFile/upload';
						editor.config.uploadFileName = 'mfile'

						let headers = {
							'x-token': sessionStorage.getItem('x-token')
						}
						editor.config.uploadImgHeaders = headers;
						editor.config.uploadImgHooks ={
							customInsert:function(insertImg, result, editor){
								const url = result.data.url;
								insertImg(url)
							}
						}
						editor.config.uploadImgMaxLength = 1
						editor.create();
						editor.txt.html(html)
							
				})

			}
		},
    setDefaultRemind(){
      let param = {
				type:this.noticeType,
				remind:editor.txt.html()
			};      
      setDefaultRemind(param).then(res => {
        if (res.code != 200) return

				this.$message.success('保存成功！')
        
      })
    },
		
  }
}
</script>

<style scoped lang="scss">
.title{
	width:550px;
	text-align:center;
	line-height:25px;
	font-size:16px;
	font-weight: bold;
	margin: auto;
}
.content{
	margin: auto;
	width:550px;
	margin-top:10px;
}
.type{
	width:550px;
	margin:auto;
	text-align:center;
}

.save{
	width:550px;
	text-align:center;
	padding:10px;
	margin: auto;
}

</style>
